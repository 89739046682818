import { Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Component } from "@angular/core";
import { Input } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { OnInit } from "@angular/core";
import { forwardRef } from "@angular/core";
import { OnDestroy } from "@angular/core";
import { ChangeDetectorRef } from "@angular/core";
import { ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ControlValueAccessor } from "@angular/forms";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material";
import { MatSelect } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { MatSelectSearchComponent } from "ngx-mat-select-search";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { startWith } from "rxjs/operators";
import { map } from "rxjs/operators";
import { switchMap } from "rxjs/operators";
import { debounceTime } from "rxjs/operators";

import { UtilsService } from "../../services";

/**
 * Вариант для выбора в выпадашке.
 */
export interface Option {

    /**
     * ID значения.
     */
    id: string;

    /**
     * Тектовое представление значения.
     */
    name: string;
}

/**
 * Компонент поля формы для выбора из списка значений с возможностью поиска.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SingleSelectComponent),
            multi: true
        }
    ],
    selector: 'single-select',
    styleUrls: ['single-select.component.scss'],
    templateUrl: 'single-select.component.html'
})
export class SingleSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
    //region Inputs

    /**
     * Входящие данные - placeholder поля.
     */
    @Input()
    placeholder: string;

    /**
     * Входящие данные - включить длинную панель для отображения опций выбора.
     *
     * Максимальная высота панели становится 80% от высоты экрана.
     */
    @Input()
    readonly longPanel: boolean = false;

    /**
     * Входящие данные - какое поле варианта выбора отображать в выпадашке. По умолчанию "name".
     */
    @Input()
    set optionField(optionField: string) {

        this._optionField = optionField;
    }

    /**
     * Входящие данные - функция для получения текстового представления варианта выбора в вариантах выбора.
     */
    @Input()
    formatOption: (option: Option) => string;

    /**
     * Входящие данные - какое поле выбранного варианта отображать. По умолчанию "name".
     */
    @Input()
    set resultField(resultField: string) {

        this._resultField = resultField;
    }

    /**
     * Входящие данные - функция для получения текстового представления выбранного варианта.
     */
    @Input()
    formatResult: (option: Option) => string;

    /**
     * Входящие данные - какое поле варианта выбора использовать в качестве значения для контрола. 
     * По умолчанию, если это поле не задано, вариант выбора и будет являться значением контрола.
     */
    @Input()
    valueField: string;

    /**
     * Входящие данные - функция для сравнения вариантов выбора, которая будет использоваться в mat-select.
     * По умолчанию в mat-select попадает функция, выполняющая сравнение по полю id.
     * При установке valueField, по умолчанию будет функция, выполняющая сравнение по заданному полю valueField.
     */
    @Input('compareWith')
    userCompareWith: (o1: any, o2: any) => boolean;

    /**
     * Входящие данные - статические значения для выбора.
     */
    @Input()
    set options(options: Option[]) {

        this._options = options;
        this._disableOrEnableControl();
        this.searchControl.setValue("");
    }

    /**
     * Входящие данные - функция динамической ajax-загрузки значения для выбора.
     */
    @Input()
    searchFn: (search: string, page: number) => Observable<Option[]> = null;

    /**
    * Входящие данные - функция меняющую строку поиска перед проверкой длины строки.
    */
    @Input()
    searchFnFilter: (search: string) => string = null;

    /**
     * Входящие данные - Минимальная длина поисковой строки после применения searchFnFilter, 
     * при которой функция searchFnFilter больше не применяется
    */
    @Input()
    searchFilterThresholdLength: number = 3;

    /**
     * Входящие данные - задержка перед выполнением поиска после последнего нажатия на клавиши.
     */
    @Input()
    searchDebounce: number = 170;

    /**
     * Входящие данные - отключать выпадашку, когда вариантов для выбора нет?
     */
    @Input()
    set disableOnEmptyOptions(disableOnEmptyOptions: boolean) {

        this._disableOnEmptyOptions = disableOnEmptyOptions;
        this._disableOrEnableControl();
    }

    /**
     * Входящие данные - поиск по вариантам включён?
     */
    @Input()
    searchEnabled: boolean;

    /**
     * Входящие данные - placeholder, пока строка поиска пуста.
     */
    @Input()
    searchPlaceholder: string;

    /**
     * Входящие данные - placeholder, что пользователю нужно начать вводить, чтобы начался поиск.
     */
    @Input()
    startSearchPlaceholder: string;

    /**
     * Входящие данные - placeholder, когда поиск не вернул результатов.
     */
    @Input()
    noSearchResultsPlaceholder: string;

    /**
     * Входящие данные - отображать вариант выбора, который имеет значение "null"?
     */
    @Input()
    hasNullOption: boolean;

    /**
     * Входящие данные - текст вариант выбора, который имеет значение "null".
     */
    @Input()
    nullOptionText: string;

    /**
     * Входящие данные - подсказка при наведении на кнопку очистки выбранного значения.
     */
    @Input()
    clearBtnTitle: string;

    /**
     * Входящие данные - кнопка очистки выбранного значения включена?
     */
    @Input()
    clearBtnEnabled: boolean = true;

    /**
     * Входящие данные - отключение контрола.
     */
    @Input()
    set selectDisabled(disabled: boolean) {

        this._selectDisabled = disabled;
        this._disableOrEnableControl();
    }

    /**
     * Входящие данные - поле обязательно для заполнения?
     */
    @Input()
    required: boolean = false;

    /**
     * Входящие данные - текст ошибки, когда поле не заполнено, но является обязательным.
     */
    @Input()
    requiredErrorMessage: string;

    /**
     * Входящие данные - отображать custom ошибку для поля?
     */
    @Input()
    customError: boolean = false;

    /**
     * Входящие данные - текст custom-ошибки.
     */
    @Input()
    customErrorMessage: string;

    /**
     * Входящие данные - логика контрола, которая определяет, когда отображать контрол в состоянии ошибки.
     */
    @Input()
    errorStateMatcher: ErrorStateMatcher;

    /**
     * Входящие данные - включить выравнивание по правому краю?.
     */
    @Input()
    rightAlign: boolean = false;

    //endregion
    //region Public fields

    /**
     * Минимальная длина поискового запроса.
     */
    @Input()
    minLengthToSearch: number = 3;

    /**
     * Игнорировать пробелы при подсчете минимальной блинны для поиска?
     */
    @Input()
    skipSpacesForMinLength: boolean = false;

    /**
     * Открывать ли при нажатии на Enter.
     */
    @Input()
    dontOpenOnEnterKey = false;

    //endregion
    //region Outputs

    /**
     * Событие, сигнализирующее о нажатии кнопки очистки.
     */
    @Output()
    clearBtnClicked: EventEmitter<void> = new EventEmitter();

    //endregion
    //region Fields

    /**
     * Флаг, что длина поискового запроса достигла нужной длины для выполнения запроса.
     */
    searchLengthReached: boolean = false;

    /**
     * FormControl привязанный к выпадашке.
     */
    valueControl = new FormControl();

    /**
     * FormControl привязанный к полю поиска.
     */
    searchControl = new FormControl();

    /**
     * Отфилтрованные статические значения для выбора согласно поисковому запросу.
     */
    filteredOptions$: Observable<Option[]>;

    /**
     * Динамически загруженные варианты для выбора согласно поисковому запросу.
     */
    searchOptions$: Subject<Option[]> = new Subject<Option[]>();

    /**
     * Флаг выполняющейся первой загрузки динамических вариантов выбора.
     */
    loading: boolean = false;

    /**
     * Флаг выполняющейся последующей подгрузки динамических вариантов выбора.
     */
    infinityLoading: boolean = false;

    /**
     * Флаг того, что часть динамических вариантов выбора загружена.
     */
    loaded: boolean = false;

    /**
     * Выбранное значение.
     */
    selectedValue: Option = null;

    /**
     * Компонент выпадающего списка.
     */
    @ViewChild(MatSelect)
    matSelect: MatSelect;

    /**
     * Компонент поля поиска для выпадающего списка.
     */
    @ViewChild(MatSelectSearchComponent)
    selectSearch: MatSelectSearchComponent;

    //endregion
    //region Private fields

    /**
     * Callback, когда выбранное в выпадашке значение изменилось.
     *
     * @private
     */
    private _changeCallback: Function = (_: any) => {};

    /**
     * Callback, когда пользователь начал взаимодействовать с выпадашкой.
     *
     * @private
     */
    private _touchCallback: Function = (_: any) => {};

    /**
     * Подписка на изменения выбранного значения в выпадашке.
     *
     * @private
     */
    private _valueChangesSubscription: Subscription;

    /**
     * Поле для хранения значений для выбора.
     *
     * @private
     */
    private _options: Option[] = [];

    /**
     * Поле для хранения флага отключения контрола при отсутствии вариантов для выбора.
     *
     * @private
     */
    private _disableOnEmptyOptions: boolean = true;

    /**
     * Поле для хранения поля объетка, которое отображается в выпадашке.
     *
     * @private
     */
    private _optionField: string;

    /**
     * Поле для хранения поля объекта, которое отображается для выбранного значения.
     *
     * @private
     */
    private _resultField: string;

    /**
     * Функция для сравнения вариантов выбора по id.
     *
     * @private
     */
    private _compareWith = (o1: any, o2: any) => o1 && o2 && o1['id'] === o2['id'];

    /**
     * Функция для сравнения вариантов выбора по заданному полю.
     *
     * @private
     */
    private _compareWithField = (o1: any, o2: any) => o1 && o2 && o1[this.valueField] === o2[this.valueField];

    /**
     * Поле для хранения флага отключения контрола.
     *
     * @private
     */
    private _selectDisabled: boolean = false;


    /**
     * Объект глобальной отписки.
     */
    private _globalUnsubscribe$: Subject<void> = new Subject();

    //endregion
    //region Ctor

    constructor(
        private _translateService: TranslateService,
        private _cd: ChangeDetectorRef,
        private _utilService: UtilsService,
    ) {}

    //endregion
    //region Hooks

    ngOnInit() {

        // Поиск среди статически заданных вариантов выбора.
        this.filteredOptions$ = this.searchControl.valueChanges
            .pipe(
                startWith(''),
                map(search => this.filterOptions(search))
            );

        // Поиск динамически подгружаемых вариантов выбора.
        if (this.searchFn) {

            this.searchControl.valueChanges
                .pipe(
                    takeUntil(this._globalUnsubscribe$),
                    debounceTime(this.searchDebounce),
                    map((search: string) => search.trim()),
                    switchMap((search: string) => {

                        this.searchOptions$.next([]);
                        this.searchLengthReached = false;

                        if (this.searchFnFilter) {
                            
                            const filteredSearch: string = this.searchFnFilter(search);

                            if (search !== filteredSearch) {
                    
                                const filteredSearchLength: number = 
                                    (this.skipSpacesForMinLength && filteredSearch.replace(/\s/g, "") || filteredSearch)
                                    .length;
                    
                                if (filteredSearchLength < this.searchFilterThresholdLength) {
    
                                    search = filteredSearch;
                                }
                            } 
                        }


                        const searchLength =
                            (this.skipSpacesForMinLength && search.replace(/\s/g, "") || search).length;

                        if (searchLength < this.minLengthToSearch) {

                            this.loading = false;

                            return of([] as Option[]);
                        }
                        else {

                            this.loading = true;

                            return this.searchFn(search, 1);
                        }
                    }),
                )
                .subscribe((options: Option[]) => {

                    this.searchOptions$.next(options);
                });
        }
        else {

            this.searchOptions$.next([]);
        }

        this.searchOptions$
            .pipe(takeUntil(this._globalUnsubscribe$))
            .subscribe((options: Option[]) => {

                this.loading = false;
                this.infinityLoading = false;
                this.loaded = !!(options && options.length);
            });

        this._valueChangesSubscription = this.valueControl.valueChanges
            .subscribe((value: Option) => {
                this.selectedValue = value;
                this._changeCallback(value);
                setTimeout(() => this._cd.markForCheck(), 100);
            });
    }

    ngOnDestroy() {

        this._valueChangesSubscription.unsubscribe();
        this.searchOptions$.complete();
        this._globalUnsubscribe$.complete();
    }

    //endregion
    //region ControlValueAccessor

    writeValue(value: any) {

        if (value !== undefined && this.valueControl.value !== value) {

            this.valueControl.setValue(value);
        }
    }

    /**
     * Регистрирует функцию для уведомлении контрола формы об изменении значения.
     *
     * Вызов полученной функции fn оборачивается в try/catch и одна из возможных ошибок игнорируется.
     *
     * Подобная ошибка происходит, когда form control, привязанный к single-select, пересоздаётся, то
     * регистрируется другое значение функции fn, вызов которой приводит к ошибке. После чего вновь
     * регистрируется нормальное значение fn, вызов которой не приводит к ошибкам. При этом на работоспособность
     * single-select возникающая ошибка никак не влияет. Но чтобы не мусорить в логе, эта ошибка игнорируется.
     *
     * Пример, где возникает такая ситуация - это страница обработки документа оператором. Если при открытом
     * просмотрщике страниц документа нажать на кнопку выброса страниц, то состояние документы изменяется,
     * новый экземпляр документа приходит в @Input, выполняется пересоздание form control'ов.
     * Три single-select'а расположенные на этой странице ловят это исключение.
     *
     * @param fn Функция для уведомления контрола формы об изменении значения.
     */
    registerOnChange(fn: Function): void {

        this._changeCallback = (value: Option) => {

            try {

                fn(value);
            }
            catch (e) {

                const formControlError = "There is no FormControl instance attached to form control element with name";

                if (!e.toString().includes(formControlError)) {

                    throw e;
                }
            }
        };
    }

    registerOnTouched(fn: Function) {

        this._touchCallback = fn;
    }

    //endregion
    //region Getters and Setters

    /**
     * Возвращает функцию для сравнения вараинтов выбора для mat-select.
     */
    get compareWith(): (o1: any, o2: any) => boolean {

        if (this.userCompareWith) {

            return this.userCompareWith;
        }

        if (this.valueField) {

            return this._compareWithField;
        }

        return this._compareWith;
    }

    /**
     * Возвращает Observable placeholder'а, пока строка поиска пуста.
     */
    get searchPlaceholder$(): Observable<string> {

        if (this.searchPlaceholder) {

            return of(this.searchPlaceholder);
        }
        else {

            return this._translateService.get('search.label');
        }
    }

    /**
     * Возвращает Observable placeholder'а, что пользователю нужно начать вводить, чтобы начался поиск.
     */
    get startSearchPlaceholder$(): Observable<string> {

        if (this.startSearchPlaceholder) {

            return of(this.startSearchPlaceholder);
        }
        else {

            return this._translateService.get('search.startTyping');
        }
    }

    /**
     * Возвращает Observable placeholder'а, когда поиск не вернул результатов.
     */
    get noSearchResultsPlaceholder$(): Observable<string> {

        if (this.noSearchResultsPlaceholder) {

            return of(this.noSearchResultsPlaceholder);
        }
        else {

            return this._translateService.get('search.empty.result');
        }
    }

    /**
     * Возвращает значения для выбора.
     */
    get options(): Option[] {

        return this._options;
    } 

    /**
     * Возвращает поле варианта выбора, которое отображается в выпадашке.
     */
    get optionField(): string {

        return (this._optionField ? this._optionField : 'name');
    }

    /**
     * Возвращает поле выбранного варианта, которое отображается.
     */
    get resultField(): string {

        return (this._resultField ? this._resultField : 'name');
    }

    /**
     * Placeholder для приглашения ввода для начала поиска виден?
     */
    get isStartTypingPlaceholderVisible(): boolean {

        return !!(
            (!this.options || this.options.length === 0)
            && this.searchFn
            && !this.loading
            && !this.infinityLoading
            && !this.loaded
            && (!this.searchControl.value || !this.searchLengthReached)
        );
    }

    /**
     * Вариант с выбранным значением нужно отображать?
     */
    get isSelectedValueExists(): boolean {

        let result: boolean = !!this.searchFn;

        if (this.selectedValue instanceof Object) {

            result = result && this.selectedValue.id != undefined;
        }
        else {

            result = result && this.selectedValue != undefined;
        }

        return result;
    }

    /**
     * Текст, что ничего не найдено виден?
     */
    get isNoSearchResultsVisible(): boolean {

        return (
            !this.isStartTypingPlaceholderVisible
            && !this.loading
            && !this.infinityLoading
            && !this.loaded
            && this.searchFn
            && this.searchControl.value
        );
    }

    /**
     * Возвращает класс для панели вариантов выбора.
     */
    get panelClass(): string {

        const mainClass: string = "single-select-panel";
        let result: string = mainClass;
        if (this.longPanel) {

            result += ` ${mainClass}_long`;
        }

        return result;
    }

    //endregion
    //region Public

    /**
     * Возвращает текстовое представление варианта для выбора.
     * 
     * @param option Вариант для выбора. 
     */
    getOptionText(option: Option): string {

        let text = '';

        if (this.formatOption) {

            text = this.formatOption(option);
        }
        else {

            text = option[this.optionField];
        }

        if (this.searchControl.value) {

            text = this._utilService.highlight(text, this.searchControl.value, "highlight");
        }

        return text;
    }

    /**
     * Возвращает текстовое представление выбранного варианта.
     *
     * @param selected Выбранный вариант.
     */
    getResultText(selected: Option): string {

        let text = '';

        if (this.formatResult) {

            text = this.formatResult(selected);
        }
        else {

            text = selected[this.resultField];
        }

        return text;
    }

    /**
     * Возвращает значение варианта для выбора, которое будет использоваться как значение всего контрола.
     * 
     * @param option Вариант для выбора.
     */
    getOptionValue(option: Option): any {

        let value = option;

        if (this.valueField) {

            value = option[this.valueField];
        }

        return value;
    }

    /**
     * Возвращает значение для варианта выбора обозначающего 'null'-значение.
     */
    getNullOptionValue(): any {

        let value: any = {
            id: 'null'
        }; 

        if (this.valueField) {

            value = 'null';
        }
        
        return value;
    }

    /**
     * Открывает выпадашку, подставляет заданную поисковую строку и запускает поиск.
     *
     * @param search Поисковая строка.
     */
    openAndSearch(search: string): void {

        this.matSelect.open();
        this.selectSearch.searchSelectInput.nativeElement.value = search;
        this.searchControl.setValue(search);
    }

    //endregion
    //region Events

    /**
     * Обработчик клика очистки значения выбранного в выпадашке.
     */
    clearBtnClickHandler(event: any) {

        this.valueControl.reset(null);
        this.clearBtnClicked.emit();

        // Предотвращаем дальнейшее всплытие, т.к. иначе выпадашка откроется.
        event.stopPropagation();
    }

    /**
     * Обработчик нажатия кнопок в поле поиска.
     */
    keydownHandler(event: any) {

        // Кнопки HOME и END останавливаем от всплытия, т.к. иначе mat-select сделает event.preventDefault()
        // для них, что не даст в поле поиска перемещатся в начало или конец поля.
        if (event.keyCode === 35 || event.keyCode === 36) {

            event.stopPropagation();
        }
    }

    //endregion
    //region Private

    /**
     * Фильтрует варианты для выбора согласно поиковой строке.
     * 
     * @param search Поисковая строка.
     */
    private filterOptions(search: string): Option[] {

        search = search.toLowerCase();
        return this.options.filter(option => this.getOptionText(option).toLowerCase().includes(search));
    }

    /**
     * Включает или отключает контрол в зависимости от входящих данных.
     */
    private _disableOrEnableControl() {

        if (this._selectDisabled) {

            this.valueControl.disable();
        }
        else {

            if (
                this._disableOnEmptyOptions
                && (!this.options || this.options.length === 0)
                && !this.searchFn
            ) {

                this.valueControl.disable();
            }
            else {
    
                this.valueControl.enable();
            }
        }
    }

    //endregion
}
