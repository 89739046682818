import { OnDestroy } from "@angular/core";
import { OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { Output } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { Option } from "src/app/common/components/single-select/single-select.component";
import { Country } from "src/app/common/models/country.model";
import { RootState } from "src/app/root/store/states/root.state";
import { ApiResponse } from "../../models";
import { Company } from "../../models";
import { debounceTime, filter } from "rxjs/operators";
import { distinctUntilChanged } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";

/**
 * Компонент диалога создания и редактирования компании.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "operator-company-form",
    styleUrls: ["company-form.component.scss"],
    templateUrl: "company-form.component.html",
})
export class CompanyFormComponent implements OnInit, OnDestroy {
    //region Input

    /**
     * Список стран.
     */
    @Input()
    countries: Country[] = [];

    /**
     * Страна из настроек распознавания.
     */
    @Input()
    recognitionSettingsCountryAlpha3Code: string;

    /**
     * Сетер установки ошибок для полей.
     *
     * @param errorData информация об ошибках.
     */
    @Input()
    errorData: ApiResponse;

    /**
     * Сеттер имени заголовка.
     */
    @Input()
    headerName: string;

    /**
     * Сеттер id компонента.
     */
    @Input()
    componentId: string;

    /**
     * Отображать ли заголовок.
     */
    @Input()
    showHeader: boolean = true;

    /**
     * Имя кнопки отправки данных.
     */
    @Input()
    submitButtonName: string;

    /**
     * Иностранная компания?
     */
    @Input()
    foreign: boolean = false;

    /**
     * Флаг - нужно ли игнорировать изменения в формах?
     */
    @Input()
    ignoreChange: boolean = false;

    /**
     * Компонент в состоянии загрузки.
     */
    @Input()
    set loading(loading: boolean) {
        this._loading = loading;
        if (this.formGroup) {
            if (loading) {
                this.formGroup.disable();
            }
            else {
                this.formGroup.enable();
            }
        }
    }

    /**
     * Сеттер установки состояния формы.
     *
     * @param company Состояние для установки.
     */
    @Input()
    set value(company: Company) {

        if (this.ignoreChange) {

            return; 
        }

        this.createOrClearForm();

        if (company) {

            this._companyId = company.id;
            const countryAlpha3Code = company.countryAlpha3Code
                || (this.foreign ? null : (this.recognitionSettingsCountryAlpha3Code || "RUS"));
            const firstFoundCountry: Country[] = this.countries.filter((country: Country) =>
                country.alpha3Code === countryAlpha3Code
            );

            const countryToSet: Option = firstFoundCountry.length > 0 ?
                { id: firstFoundCountry[0].alpha3Code, name: firstFoundCountry[0].shortName }
                : null;

            this.formGroup.patchValue({
                name: company.name,
                shortName: company.shortName,
                inn: company.inn,
                kpp: company.kpp,
                taxId: company.taxId,
                country: countryToSet,
                address: company.address && company.address.fullAddress,
            });
        }
    }

    //endregion
    //region Output

    /**
     * Исходящее событие отправки данных компании.
     */
    @Output()
    public submitCompany: EventEmitter<Company> = new EventEmitter();

    /**
     * Исходящее событие - данные в форме изменились.
     */
    @Output() 
    public formChanged = new EventEmitter<string>();

    //endregion
    //region Fields

    /**
     * Группа формы для полей диалога.
     */
    formGroup: FormGroup;

    /**
     * Состяоние загрузки
     */
    get loading(): boolean {
        return this._loading;
    }

    /**
     * Компания из Казахстана?
     */
    get kazakhCompany(): boolean {

        return this.formGroup.controls.country.value && this.formGroup.controls.country.value.id === "KAZ";
    }

    /**
     * Список стран для вывода в выпадашке.
     */
    countriesAsOptions: Option[] = [];

    /**
     * Список стран для вывода в выпадашке.
     */
    rusAndKazCountriesAsOptions: Option[] = [];

    //endregion
    //region Private

    /**
     * Состояние приложения.
     */
    private _store: Store<RootState>;

    /**
     * Объект глобальной отписки.
     */
    private _subject = new Subject();

    /**
     * Приватное значение загрузки.
     */
    private _loading: boolean = false;

    /**
     * ID компании.
     */
    private _companyId: string;

    //endregion
    //region С-tor

    /**
     * Конструктор компонента диалога создания компании.
     *
     * @param _formBuilder Построитель форм
     * @param store Состояние приложения.
     */
    constructor(
        private _formBuilder: FormBuilder,
        store: Store<RootState>,
    ) {

        this._store = store;
    }

    //endregion
    //region Hooks

    ngOnInit(): void {
        if (!this.formGroup) {
            this.createOrClearForm();
        }

        this.countriesAsOptions = this.countries.map((country: Country): Option =>
            ({ id : country.alpha3Code, name: country.shortName })
        );

        this.rusAndKazCountriesAsOptions = this.countriesAsOptions
            .filter(option => option.id === "KAZ" || option.id === "RUS");

        if (!this.value && !this.formGroup.controls.country.value) {

            let countryToSet = null;
            if (!this.foreign) {

                const countryCodeToSet = this.recognitionSettingsCountryAlpha3Code === "KAZ" && "KAZ" || "RUS";
                countryToSet = this.rusAndKazCountriesAsOptions.find(option => option.id === countryCodeToSet);
            }
            this.formGroup.controls.country.setValue(countryToSet);
        }

        this.formGroup.valueChanges
            .pipe(
                debounceTime(200),
                takeUntil(this._subject),
                distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
                filter(() => !this.ignoreChange),
            )
            .subscribe(() => {

                    this.formChanged.emit(this.componentId);
            });
    
    }

    ngOnDestroy(): void {
        
        this._subject.next();
    }

    //endregion
    //region Public

    /**
     * Обработчик нажатия клавиши отправки данных.
     */
    submitHandler() {

        if (this.formGroup.valid) {

            const companyBaseInfo: Company = {
                id: this._companyId,
                name: this.formGroup.controls.name.value,
                shortName: this.formGroup.controls.shortName.value,
            };

            if (this.foreign && !this.kazakhCompany) {

                const taxId: string = this.formGroup.controls.taxId.value;

                let fullAddress: string = this.formGroup.controls.address.value;
                if (fullAddress === "") {

                    fullAddress = null;
                }
                this.submitCompany.emit({
                    ...companyBaseInfo,
                    foreign: true,
                    taxId: taxId,
                    address: {fullAddress: fullAddress},
                    countryAlpha3Code: this.formGroup.controls.country.value.id,
                });
            }
            else {

                this.submitCompany.emit({
                    ...companyBaseInfo,
                    inn: this.formGroup.controls.inn.value,
                    kpp: !this.kazakhCompany && this.formGroup.controls.kpp.value || null,
                    countryAlpha3Code: this.formGroup.controls.country.value.id,
                });
            }
        }
    }

    /**
     * Очистка или создание пустой формы если ее еще нет.
     */
    createOrClearForm() {
        if (this.formGroup) {
            this.formGroup.patchValue({
                shortName: null,
                name: null,
                inn: null,
                kpp: null,
                taxId: null,
                country: null,
                address: null,
            });
        }
        else {
            this.formGroup = this._formBuilder.group({
                shortName: null,
                name: null,
                inn: null,
                kpp: null,
                taxId: null,
                country: null,
                address: null,
            });
        }

        this.errorData = null;

    }

    /**
     * Полное описание ошибки.
     */
    getErrorMessage(): string {
        let result = null;
        if (this.errorData) {
            result = "" + this.errorData.errorMessage;
            if (this.errorData.details) {
                result = this.errorData.details.reduce((str, error) => str + " " + error.errorMessage, result);
            }
        }
        return result;
    }

    /**
     * Форматирование вывода результата поиска страны.
     *
     * @param option Страна.
     */
    formatCountry(option: Option): string {

        return `${option.id} - ${option.name}`;
    }

    //endregion

}
